<template>
  <div
    class="step-cover"
    v-loading='loading'
  >
    <div class="step-wrap">
      <!-- <span class="importRecord" @click="recordsClick()">导入记录</span> -->
      <h3 class="step-title">批量设置租金计划</h3>
      <i
        class="el-icon-close step-close"
        @click="close"
      />
      <div
        class="step-list"
        v-if="step<4"
      >
        <div :class="['step', step>=1?'active':'']">
          <i>1</i>
          <span>上传文件</span>
          <i class="icon-page-next step-arrow" />
        </div>
        <div :class="['step', step>=2?'active':'']">
          <i>2</i>
          <span>数据预览</span>
          <i class="icon-page-next step-arrow" />
        </div>
        <div :class="['step', step>=3?'active':'']">
          <i>3</i>
          <span>导入数据</span>
        </div>
      </div>
      <!-- 步骤一上传文件 -->
      <div
        class="step-content"
        v-if="step===1"
      >
        <div class="step-item">
          <img
            class=""
            src="@/assets/images/workBench/downLoad.png"
          >
          <div>
            <p class="step-item-title">填写导入数据信息</p>
            <p class="step-item-tip">请先下载铺位模板并按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</p>
            <!-- <div
              class="default-btn"
              @click="downLoadFile">下载铺位模板</div> -->
            <div class="step-select-brand">
              <div class="uploadShopClass">
              </div>
              <div
                class="default-btn"
                @click="downLoadFile"
                style="display:inline-block"
              >导出模板</div>
            </div>
          </div>
        </div>
        <div class="step-item">
          <img
            class=""
            src="@/assets/images/workBench/file.png"
          >
          <div>
            <p class="step-item-title">上传填好的信息表 </p>
            <p class="step-item-tip">文件后缀名必须为xls 或xlsx （即Excel格式），文件大小不得大于10M，最多支持导入3000条数据</p>
            <el-upload
              v-show="showFile"
              class="upload-demo"
              ref="upload"
              :headers="token"
              :action="uploadUrl"
              :data="{
                projectId: projectId,
                decisionId: decisionId,
              }"
              :file-list="fileList"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :on-success="fileSuccess"
              :on-error="fileError"
              :auto-upload="true"
            >
              <div class="default-btn">上传文件</div>
            </el-upload>
            <div
              class="file-info"
              v-if="!showFile"
              style="color:#333"
            >{{ tempFile.name }}<i
                class="el-icon-circle-close "
                @click="deleteFile"
              ></i></div>
          </div>
        </div>
        <div class="step-tip">
          <div class="step-tip-title"><i class="icon-small-tip"></i>特别提示</div>
          <p>1）批量导入租金计划时，将覆盖当前租决文件内原有的租金计划，请谨慎使用</p>
          <p>2）如某些铺位不需要进行租金计划设置，请删除对应数据行再进行上传，空数据将导致验证失败。</p>
        </div>
        <div class="step-footer">
          <div
            class="default-btn w200 mr-24"
            @click="close"
          >
            取消
          </div>
          <el-button
            class="w200 h40"
            type="primary"
            @click="next"
          >下一步</el-button>
        </div>
      </div>
      <!-- 步骤二数据预览 -->
      <div
        class="step-content"
        v-if="step===2"
      >
        <div class="step-item">
          <img
            class=""
            src="@/assets/images/workBench/reflesh.png"
          >
          <div>
            <p class="step-item-info"><span>新增租金计划：{{overViewData.list && overViewData.list.length}}个</span></p>
            <p class="step-item-info">异常数据：<span class="error">{{overViewData.errs.length}}条</span></p>
          </div>
        </div>
        <div
          class="step-tip error"
          v-if="overViewData.errs && overViewData.errs.length > 0"
        >
          <div class="step-tip-title">
            <i class="icon-warning-new"></i>异常提示
          </div>
          <p
            v-for="(item, index) in overViewData.errs"
            :key="index"
          >第{{item.rowNum}}行：{{item.msg}}</p>
        </div>
        <div class="step-footer">
          <div
            class="default-btn w200 mr-24"
            @click="resetFile"
          >
            重新上传
          </div>
          <el-button
            class="w200 h40"
            type="primary"
            :class="{'disabled-sty': !canUpload}"
            @click="sureInsert"
          > 确认导入</el-button>
        </div>
      </div>
      <!-- 步骤三导入数据 -->
      <div
        class="step-content"
        style="margin-top:100px;margin-bottom:100px"
        v-if="step===3"
      >
        <div class="step-progress">
          <div class="el-progress el-progress--line el-progress--text-inside">
            <div class="el-progress-bar">
              <div
                class="el-progress-bar__innerText"
                :style="{left: progress+'%'}"
              >{{ progress }}%</div>
              <div
                class="el-progress-bar__outer"
                :style="{height: '18px'}"
              >
                <div
                  class="el-progress-bar__inner"
                  :style="{width: progress+'%'}"
                />
              </div>
            </div>
          </div>
        </div>
        <p class="step-progress-info text-center">
          正在批量上传<span class="error">{{overViewData.list.length}}</span>条租金计划
        </p>
      </div>
      <!-- 步骤四上传成功 -->
      <div
        class="step-content"
        v-if="step===4"
      >
        <div class="step-success">
          <img
            class=""
            src="@/assets/images/workBench/success.png"
          >
          <p class="step-success-title">批量上传成功</p>
          <p class="step-success-info">成功上传{{overViewData.list.length}}条租金计划</p>
        </div>
        <div
          class="step-footer"
          style="margin-bottom: 60px"
        >
          <el-button
            class="w200 h40"
            type="primary"
            @click="confirm"
          > 确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { getCookie } from '@/utils'
export default {
  data () {
    return {
      canUpload: false,
      step: 1,
      loading: false,
      fileList: [],
      tempFile: {},
      showFile: true,
      progress: 0,
      runControl: null,
      ajaxFinish: false,
      selectProjectId: 12,
      projectName: '',
      projectList: [],
      projectWidth: 100,
      planWidth: 120,
      uploadUrl: '',
      token: {
        token: getCookie('wisdom_token')
      },
      overViewData: {}
    }
  },
  props: {
    decisionId: {
      type: Number
    },
    projectId: {
      type: Number
    }
  },
  mounted () {
    window.addEventListener('click', (e) => {
      if (e.target.className === 'step-cover') {
        this.close()
      }
    })
  },
  created () {
    this.uploadUrl = api.importBerth
    this.getBerthProjectFunc()
  },
  methods: {
    getBerthProjectFunc () {
      this.axios.post(api.getBerthProjectList)
        .then((res) => {
          this.projectList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.projectName
            obj.value = item.id
            return obj
          })
          const obj = res.data.data.find(item => {
            return item.id === this.projectId
          })
          this.projectName = obj.label
          console.log('obj', obj)
        })
    },
    close () {
      this.$emit('closePanel', true)
    },
    confirm () {
      this.$emit('closePanel', true)
    },
    whichStep (step) {
      this.step = step
    },
    beforeUpload (file, fileList) {
      const isExcel = file.name.split('.')[1] === 'xlsx' || file.name.split('.')[1] === 'xls'
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isExcel) {
        this.$message.error('上传文件只能是 xlsx或者xls 格式!')
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isExcel && isLt10M
    },
    fileSuccess (res, fileList) {
      if (res.code === 0) {
        this.overViewData = res.data
        console.log('resxxxxx', res.data)
        this.overViewData.list = res.data.rentPlanVOList
        this.canUpload = Boolean(res.data.rentPlanVOList && res.data.rentPlanVOList.length)
        this.fileList = [fileList]
      } else {
        this.$message.error('上传失败，请重试')
      }
      this.loading = false
      this.whichStep(2)
    },
    fileError () {
      this.loading = false
      this.$message.error('跳转失败，请重试！')
    },
    deleteFile () {
      this.tempFile = this.fileList[0]
      this.fileList = []
      this.showFile = true
    },
    resetFile () {
      this.fileList = []
      this.whichStep(1)
    },
    sureInsert () {
      console.log('this.overViewData.list', this.overViewData)
      if (this.overViewData.list && this.overViewData.list.length === 0) {
        this.$message.error('无可导入数据，请重新上传文件')
        return
      }
      this.whichStep(3)
      this.$nextTick(() => {
        this.runProgress()
      })
      let apiUrl = ''
      apiUrl = api.leasingPlanBatchSave
      this.axios.post(apiUrl, this.overViewData.list)
        .then((res) => {
          this.$emit('addDropBrandEmitSuccess')
        })
    },
    runProgress () {
      this.runControl = setInterval(() => {
        if (!this.ajaxFinish && this.progress < 100) {
          this.progress++
        } else if (this.ajaxFinish && this.progress < 90) {
          this.progress = 100
        }
        if (this.progress === 100) {
          this.whichStep(4)
        }
      }, 60)
    },
    next () {
      if (this.fileList.length === 0) {
        this.$message.error('请上传填好的信息表')
        return
      }
      this.loading = true
      const el = this.$refs.upload
      el.submit()
    },
    exportPlan () {
      return this.axios({ // 用axios发送post请求
        method: 'get',
        url: api.downloadBerth, // 请求地址
        params: { projectId: this.projectId, decisionId: this.decisionId }, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    downLoadFile () {
      this.loading = true
      // const date = new Date()
      // const year = date.getFullYear()
      // const month = date.getMonth() + 1
      // const day = date.getDate()
      this.exportPlan().then(res => { // 处理返回的文件流
        console.log('res', res)
        const blob = new Blob([res.data])
        const fileName = '【' + this.projectName + '】' + '铺位租金计划导入模板.xlsx'
        // 兼容ie
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)
        }
        this.loading = false
      })
    },
    recordsClick () {
      const invesmtentHref = this.$router.resolve({
        path: '/importRecords'
      })
      window.open('/' + invesmtentHref.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.w200
  width 200px !important
.step-cover
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  overflow auto
  background rgba(0, 0, 0, 0.5)
  Z-index 1111
.step-wrap
  width 900px
  background-color #ffffff
  border-radius 6px
  position relative
  margin 0 auto
  margin-top 80px
  padding 35px 20px
  .importRecord
    position absolute
    bottom 22px
    left 28px
    font-size 14px
    color #FFA134
    cursor pointer
.step-close
  position absolute
  right 22px
  top 25px
  color #D9D9D9
  cursor pointer
  font-size 16px
.step-title
  color #333
  font-size 18px
  margin-bottom 32px
  font-weight 500
.step-list
  line-height 54px
  border 1px solid #FFA134
  display flex
  align-items center
  justify-content space-around
  border-radius 3px
  .step
    color #666666
    font-size 16px
    i:first-child
      font-style normal
      width 20px
      height 20px
      border-radius 50%
      background-color #D9D9D9
      color #ffffff
      font-size 12px
      display inline-block
      vertical-align text-bottom
      margin-right 6px
      line-height 20px
      text-align center
    .step-arrow
      margin-left 80px
      font-size 30px
      vertical-align sub
      color #D9D9D9
    &.active
      color #FFA134
      i:first-child
        background-color #FFA134
      .step-arrow
        color #FFA134
.step-content
  margin-top 40px
  .step-item
    margin-bottom 36px
    display flex
    align-items center
    padding 18px 24px 10px 0
    box-shadow 0px 5px 10px 0px rgba(4, 35, 78, 0.1)
    margin-left 12px
    img
      width 110px
      height 110px
      margin-right 24px
      margin-left -15px
    .step-item-title
      font-size 20px
      color #333
      line-height 24px
    .step-item-tip
      color #999
      font-size 14px
      line-height 30px
  .step-item-info
    color #333
    font-size 16px
    line-height 30px
    span
      margin-right 24px
    .error
      color #FE6D34
.default-btn
  border-radius 3px
  line-height 40px
  height 40px
  text-align center
  color #FFA134
  font-size 14px
  border 1px solid rgba(255, 161, 52, 1)
  width 125px
  cursor pointer
.step-tip
  padding 15px 0
  color #FFBD70
  margin-left 20px
  background rgba(255, 208, 153, 0.05)
  &.error
    color #FE6D34
    .step-tip-title
      i
        margin-right 3px
        color #FE6D34
        font-size 16px
        vertical-align top
  p
    font-size 14px
    line-height 24px
.step-tip-title
  font-size 16px
  line-height 24px
  i
    margin-right 3px
    color #FFBD70
    font-size 24px
    vertical-align text-bottom
.step-footer
  display flex
  align-items center
  justify-content center
  margin-top 48px
.mr-24
  margin-right 24px
.error
  color #FE6D34
.step-progress
  margin 0 auto
  width 536px
// 进度条
.el-progress-bar__inner
  background linear-gradient(90deg, rgba(42, 166, 200, 1) 0%, rgba(73, 207, 197, 1) 100%)
.el-progress-bar__innerText
  position relative
  top -10px
  left 100%
  height 30px
  background linear-gradient(45deg, #2aa6c8 0%, #49cfc5 100%)
  border-radius 13px
  text-align center
  line-height 30px
  padding 0 10px
  color #fff
  margin-left -20px
  transition left 0.6s ease
  &:after
    content ''
    width 0
    height 0
    border-right 5px solid transparent
    border-left 5px solid transparent
    border-top 5px solid #49cfc5
    position absolute
    bottom -5px
    left 50%
    margin-left -5px
.step-progress-info
  color #333
  font-size 18px
  margin-top 22px
  text-align center
// 成功
.step-success
  text-align center
  margin-top 45px
  .step-success-title
    color #333
    font-size 22px
    margin 15px
  .step-success-info
    color #999999
    font-size 16px
.file-info
  color #FFA134
  line-height 40px
  border 1px solid #ffa134
  padding 0 13px
  position relative
  width 440px
  border-radius 3px
  i
    color #FFA134
    font-size 20px
    position absolute
    top 10px
    right 18px
    cursor pointer
.step-select-brand
  margin-top 5px
  .default-btn
    border-top-left-radius 0
    border-bottom-left-radius 0
    height 41px
.h40
  height 42px
</style>
<style lang="stylus">
// 下拉框的样式
.whiteSelectDown
  background-color #ffffff
  .el-select-dropdown__item.hover
    background-color rgba(255, 239, 211, 0.3)
    color #FCA000
  .el-select-dropdown__item
    color #333
  .el-select-dropdown__item.selected:hover
    background-color rgba(255, 239, 211, 0.3) !important
    color #FCA000 !important
.uploadShopClass
  .el-select
    margin-top 0
    width 260px
  input[placeholder], [placeholder], *[placeholder]
    color #333 !important
    font-size 16px
    padding-right 0
  .el-input__inner
    background rgba(255, 255, 255, 1)
    border none
  .el-select .el-input .el-input__icon
    color #999999
.upload-demo
  margin-top 5px
</style>
