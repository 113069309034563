<template>
  <el-drawer
    title=""
    class="black-drawer"
    :size="'895px'"
    :append-to-body="external"
    :visible.sync="drawer"
    :before-close="handleClose"
    :with-header="false"
  >
    <div class="drawer-header">
      编辑租金计划
      <i class="el-icon-close fr pointer" @click="handleClose"></i>
    </div>
    <div class="drawer-wrap is-scroll-y" :style="'height:'+wrapHeight">
      <h3 class="w-title-border-left" style="margin-top:0">基础信息</h3>
      <span
        class="link-span pointer"
        @click="toBerthEdit"
      >铺位管理</span>
      <ul class="form-list shadow-box">
        <li>
          <span class="requiredFields">项目名称</span>
          <div class="native-input-solid">
            <input
              type="text"
              class="disabled-sty"
              disabled
              v-model="form.projectName"
              placeholder="请输入" />
          </div>
        </li>
        <li>
          <span class="requiredFields">铺位类型</span>
          <div class="native-input-solid">
            <el-select
              class="date-picker-sty disabled-sty"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择"
              v-model="form.berthType"
              disabled
            >
              <el-option
                v-for="item in unitTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span class="requiredFields">铺位号</span>
          <div class="native-input-solid">
            <input
              type="text"
              v-model="form.berthNo"
              placeholder="请输入"
              class="disabled-sty"
              disabled />
          </div>
        </li>
        <li>
          <span class="commonFields">楼宇楼层</span>
          <div class="native-input-solid">
            <el-cascader
              class="disabled-sty date-picker-sty"
              popper-class="yellow-select-panel"
              :options="buildingFloorOptions"
              v-model="form.buildingFloor"
              collapse-tags
              placeholder="全部"
              clearable
              disabled
            ></el-cascader>
          </div>
        </li>
      </ul>
      <h3 class="w-title-border-left">租金计划</h3>
      <ul class="form-list shadow-box">
        <li>
          <span class="requiredFields">规划业态</span>
          <div class="native-input-solid">
            <el-select
              class="date-picker-sty"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择"
              v-model="form.industry1"
              @change="getInputData(form.industry1, form.mainBerthType)"
              id="industry1"
            >
              <el-option
                v-for="item in formatList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span class="requiredFields">招商品牌级次</span>
          <div class="native-input-solid">
            <el-select
              class="date-picker-sty"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择"
              v-model="form.brandLevel"
              id="brandLevel"
            >
              <el-option
                v-for="item in brandLevelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span class="requiredFields">主次力店</span>
          <div class="native-input-solid">
            <el-select
              class="date-picker-sty"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择"
              id="mainBerthType"
              @change="getInputData(form.industry1, form.mainBerthType)"
              v-model="form.mainBerthType"
            >
              <el-option
                v-for="item in storeTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span class="requiredFields">计租面积类型</span>
          <div class="native-input-solid">
            <el-select
              class="date-picker-sty"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择"
              id="rentAreaType"
              v-model="form.rentAreaType"
              @change="changeRentAreaType"
            >
              <el-option
                v-for="item in areaTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </li>
        <li>
          <span class="requiredFields">计租面积</span>
          <div class="native-input-solid">
            <input
              type="text"
              class="disabled-sty"
              disabled
              v-model="form.rentArea"
              id="rentArea"
              placeholder="请输入" />
            <span class="unit">㎡</span>
          </div>
        </li>
        <li>
          <span class="commonFields" style="width:98px">{{user.topuid === 6467 ? '物业' : '综合'}}管理费</span>
          <div class="native-input-solid">
            <input
              type="text"
              @input="GARules"
              v-model="form.managementCost"
              placeholder="请输入" />
            <span class="unit">{{form.unit}}</span>
          </div>
        </li>
        <li>
          <span class="commonFields">租赁年限</span>
          <div class="native-input-solid-double" id="doubleInput">
            <input
              type="text"
              @input="leasingLengthRules('rentMinYear')"
              @focus="setStyle(1)"
              @blur="setStyle(0)"
              v-model="form.rentMinYear"
              placeholder="请输入" />
              <span>年</span>
              <span style="margin-left:16px">~</span>
            <input
              type="text"
              @input="leasingLengthRules('rentMaxYear')"
              @focus="setStyle(1)"
              @blur="setStyle(0)"
              v-model="form.rentMaxYear"
              placeholder="请输入" />
              <span>年</span>
          </div>
        </li>
      </ul>
      <h3 class="form-item-title">租金设置</h3>
      <div class="shadow-box" v-if="form.childs && form.childs.length">
        <ul
          class="border-box form-list shadow-box"
          v-for="(item,index) in form.childs"
          :key="index">
          <li>
            <span class="requiredFields">阶段名称</span>
            <div class="native-input-solid">
              <input
                type="text"
                class="disabled-sty"
                disabled
                v-model="item.decisionStepName"
                placeholder="请输入" />
            </div>
          </li>
          <li>
            <span class="requiredFields">阶段时间</span>
            <div class="native-input-solid">
              <el-date-picker
                class="date-picker-sty disabled-sty"
                disabled
                popper-class="black-el-date-picker"
                type="daterange"
                range-separator="-"
                v-model="item.phaseTime"
                format="yyyy/MM/dd"
                value-format="yyyy/MM/dd"
                placeholder="选择日期">
              </el-date-picker>
            </div>
          </li>
          <li>
            <span class="commonFields">策略租金</span>
            <div class="native-input-solid">
              <input
                type="text"
                v-model="item.strategyPrice"
                class="disabled-sty"
                disabled
              />
              <span class="unit">{{form.unit}}</span>
            </div>
          </li>
          <li>
            <span class="commonFields">租金市场价</span>
            <div class="native-input-solid">
              <input
                type="text"
                @input="numRules(index, 'marketPrice')"
                v-model="item.marketPrice"
                placeholder="请输入" />
              <span class="unit">{{form.unit}}</span>
            </div>
          </li>
          <li>
            <span class="requiredFields">租金标准价</span>
            <div class="native-input-solid">
              <input
                type="text"
                @input="numRules(index, 'rentPrice')"
                v-model="item.rentPrice"
                autocomplete="off"
                :id="'rentPrice-'+index"
                placeholder="请输入" />
              <span class="unit">{{form.unit}}</span>
            </div>
          </li>
          <li>
            <span class="commonFields">租金保底价</span>
            <div class="native-input-solid">
              <input
                type="text"
                @input="numRules(index, 'bottomPrice')"
                v-model="item.bottomPrice"
                placeholder="请输入" />
              <span class="unit">{{form.unit}}</span>
            </div>
          </li>
          <li>
            <span class="commonFields">免租时长</span>
            <div class="native-input-solid">
              <input
                type="text"
                v-model="item.freeLength"
                @input="freeLengthRules(index)"
                placeholder="请输入" />
              <span class="unit">天</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="drawer-footer">
      <span
        @click="handleSubmit"
        class="button-basic button-primary"
        :class="{'disabled-sty': notYet}"
      >保存</span>
      <span @click="handleClose" class="button-basic button-low">取消</span>
    </div>
  </el-drawer>
</template>

<script>
import dayjs from 'dayjs'
import api from '@/api'
import { debounce } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: '',
  data () {
    return {
      notYet: true,
      drawer: false,
      form: {},
      unitTypeOptions: [
        { label: '全部', value: 0 },
        { label: '铺位', value: 1 },
        { label: '广告位', value: 2 },
        { label: '场地', value: 3 },
        { label: '写字楼', value: 4 }
      ],
      storeTypeOptions: [
        { label: '普通店', value: 0 },
        { label: '主力店', value: 1 },
        { label: '次主力店', value: 2 }
      ],
      areaTypeOptions: [
        { label: '建筑面积', value: 1 },
        { label: '使用面积', value: 2 }
      ],
      // 编辑租金计划管理
      // brandLevelOptions: [
      //   { label: '不限', value: 1 },
      //   { label: '标杆', value: 2 },
      //   { label: '标准', value: 3 },
      //   { label: '非标', value: 4 }
      // ],
      brandLevelOptions: [
        { label: '不限', value: 1 },
        { label: 'S', value: 2 },
        { label: 'A', value: 3 },
        { label: 'B', value: 4 },
        { label: 'C', value: 5 },
        { label: 'D', value: 6 }
      ],
      planFormatOptions: [],
      formatList: [],
      buildingFloorOptions: []
    }
  },
  mounted () {
    this.getFilterFloorFunc()
    this.getDataFormatList()
    this.getModularListFunc()
  },
  computed: {
    ...mapGetters({
      token: 'token',
      user: 'user'
    }),
    wrapHeight: () => {
      return document.documentElement.clientHeight - 145 + 'px'
    }
  },
  props: {
    projectId: {
      type: Number
    },
    decisionId: {
      type: Number
    },
    external: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    planFormatOptions (newVal, oldValue) {
      this.formatList = this.planFormatOptions.map(item => {
        return item
      })
      this.formatList.shift()
      this.formatList.unshift({
        label: '不限',
        value: '不限'
      })
    }
  },
  methods: {
    setStyle (flag) {
      if (flag) {
        document.getElementById('doubleInput').classList.add('is-active')
      } else {
        document.getElementById('doubleInput').classList.remove('is-active')
      }
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 11
          })
        })
    },
    toBerthEdit () {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/assets/unit/details?menuCode=11&unitId=' + this.form.berthId + '&tokenval=' + this.token, '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    },
    numRules: debounce(function (index, keyword) {
      this.notYet = true
      let value = this.form.childs[index][keyword]
      if (parseInt(value) > 99999.99) {
        this.$message({
          type: 'warning',
          message: '该项取值不大于99999.99，允许小数点后两位'
        })
      }
      value = value.toString().match(/^\d{0,5}(?:\.\d{0,2})?/)[0] // 输入限制
      this.form.childs[index][keyword] = value
      this.checkForm()
    }, 300),
    getDataFormatList () {
      this.axios.post(api.getListIndustry1, {}).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.planFormatOptions = resData.map(item => {
            const tempObj = {
              label: item.name,
              value: item.id
            }
            return tempObj
          })
          this.planFormatOptions.unshift({
            label: '全部',
            value: '全部'
          })
          this.showRentForm = true
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    GARules: debounce(function (key, index) {
      let value = this.form.managementCost
      if (parseInt(value) > 99999.99) {
        this.$message({
          type: 'warning',
          message: '该项取值不大于99999.99，允许小数点后两位'
        })
      }
      value = value.toString().match(/^\d{0,5}(?:\.\d{0,2})?/)[0]
      this.form.managementCost = value
    }, 300),
    checkForm () {
      if (!this.form.industry1) {
        this.notYet = true
        return false
      }
      if (!this.form.brandLevel) {
        this.notYet = true
        return false
      }
      if (!this.form.mainBerthType && this.form.mainBerthType !== 0) {
        this.notYet = true
        return false
      }
      if (!this.form.rentAreaType) {
        this.notYet = true
        return false
      }
      for (let i = 0; i < this.form.childs.length; i++) {
        const item = this.form.childs[i]
        if (!item.rentPrice) {
          this.notYet = true
          return false
        }
      }
      this.notYet = false
    },
    freeLengthRules: debounce(function (index) {
      this.form.childs[index].freeLength = this.form.childs[index].freeLength.replace(/\D/g, '') // 输入限制
      if (this.form.childs[index].freeLength > 999) {
        this.$message({
          type: 'warning',
          message: '免租时长不能超过999天'
        })
        this.form.childs[index].freeLength = ''
      }
      this.checkForm()
    }, 300),
    handleForm (data) {
      this.drawer = true
      this.$emit('update:drawer', true)
      this.axios.post(api.getRentInfo, data).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.form = resData
          this.form.industry1 = resData.industry1 || '不限'
          this.form.brandLevel = resData.brandLevel || 1
          this.form.mainBerthType = resData.mainBerthType || 0
          this.form.rentAreaType = this.form.rentAreaType ? this.form.rentAreaType : 1 // 计租面积类型：如果存在值那么取值，否则默认不限（1）
          this.form.rentArea = this.form.rentAreaType === 1 ? resData.buildingArea : resData.useArea // 如果计租面积类型是建筑面积，那么取buildingArea，如果是试用面积，那么取useArea
          this.form.buildingFloor = [resData.building, resData.floor] // 楼层楼宇
          this.form.childs = resData.childs.map(item => {
            item.phaseTime = []
            item.phaseTime.push(dayjs(item.stepStartDate).format('YYYY/MM/DD'))
            item.phaseTime.push(dayjs(item.stepEndDate).format('YYYY/MM/DD'))
            return item
          })
          // this.getInputData(resData.industry1, resData.mainBerthType)
          this.checkForm()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getInputData (industry, mainBerthType) {
      if (!industry) {
        return
      }
      // if (!mainBerthType) {
      //   return
      // }
      const params = {
        industry: industry,
        decisionId: this.decisionId,
        mainBerthType: mainBerthType
      }
      this.axios.get(api.getInfoToParm, { params: params }).then(res => { // 根据业态以及主次力店和计算面积类型获取租赁年限、策略单价、免租时长、计算面积
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData.list && resData.list.length) {
            for (let i = 0; i < resData.list.length; i++) {
              const item = resData.list[i]
              this.form.childs[i].strategyPrice = item.strategyPrice || '-'
              this.form.childs[i].freeLength = item.freeLength
            }
          } else {
            for (let i = 0; i < this.form.childs.length; i++) {
              this.form.childs[i].strategyPrice = ''
              this.form.childs[i].freeLength = ''
            }
          }
          if (resData.rent) {
            this.form.rentMaxYear = resData.rent.rentMaxYear
            this.form.rentMinYear = resData.rent.rentMinYear
          } else {
            this.form.rentMaxYear = ''
            this.form.rentMinYear = ''
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    changeRentAreaType () {
      this.form.rentArea = this.form.rentAreaType === 1 ? this.form.buildingArea : this.form.useArea
    },
    leasingLengthRules: debounce(function (keyword) {
      const value1 = this.form.rentMinYear
      const value2 = this.form.rentMaxYear
      if (parseInt(value1) > parseInt(value2) && value1 && value2) {
        this.$message({
          type: 'warning',
          message: '后面的单元格需大于等于第一个单元格'
        })
        this.form[keyword] = ''
      }
      if (this.form[keyword] > 99) {
        this.$message({
          type: 'warning',
          message: '该值不大于99'
        })
        this.form[keyword] = this.form[keyword].substring(0, 2)
      }
    }, 900),
    handleClose () {
      this.drawer = false
      this.$emit('update:drawer', false)
    },
    unfinishedMsg () {
      this.$message({
        type: 'warning',
        message: '表单尚未填写完整'
      })
    },
    handleSubmit () {
      if (!this.form.industry1) {
        this.unfinishedMsg()
        document.getElementById('industry1').focus()
        return false
      }
      if (!this.form.brandLevel && this.form.brandLevel !== 0) {
        this.unfinishedMsg()
        document.getElementById('brandLevel').focus()
        return false
      }
      if (!this.form.mainBerthType && this.form.mainBerthType !== 0) {
        this.unfinishedMsg()
        document.getElementById('mainBerthType').focus()
        return false
      }
      if (!this.form.rentAreaType && this.form.rentAreaType !== 0) {
        this.unfinishedMsg()
        document.getElementById('rentAreaType').focus()
        return false
      }
      if (!this.form.rentArea && this.form.rentArea !== 0) {
        this.unfinishedMsg()
        document.getElementById('rentArea').focus()
        return false
      }
      for (let i = 0; i < this.form.childs.length; i++) {
        const item = this.form.childs[i]
        if (!item.rentPrice && item.rentPrice !== 0) {
          this.unfinishedMsg()
          document.getElementById('rentPrice-' + i).focus()
          return false
        }
      }
      const params = {
        berthId: this.form.berthId,
        brandLevel: this.form.brandLevel,
        childList: [],
        decisionId: this.decisionId,
        industry1: this.form.industry1,
        mainBerthType: this.form.mainBerthType,
        managementCost: this.form.managementCost,
        rentAreaType: this.form.rentAreaType,
        rentMaxYear: this.form.rentMaxYear,
        rentMinYear: this.form.rentMinYear
      }
      params.childList = this.form.childs.map(item => {
        const tempObj =
          {
            berthId: item.berthId,
            bottomPrice: item.bottomPrice,
            freeLength: item.freeLength,
            marketPrice: item.marketPrice,
            rentPrice: item.rentPrice,
            stepEndDate: dayjs(item.stepEndDate).format('YYYY/MM/DD'),
            stepId: item.stepId,
            stepStartDate: dayjs(item.stepStartDate).format('YYYY/MM/DD')
          }
        return tempObj
      })
      this.axios.post(api.editRentPlan, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.$emit('refreshData')
          this.handleClose()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getFilterFloorFunc (id) {
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          this.buildingFloorOptions = res.data.data.map(item => {
            item.children = item.children.map(item1 => {
              delete item1.children
              return item1
            })
            return item
          })
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.drawer-wrap>>>
  .disabled-sty
    .el-input__suffix
      display none
.drawer-wrap
  overflow-y auto
  padding 32px 24px
  position relative
  .link-span
    position absolute
    top 22px
    right 22px
    color #F89407
    font-size 14px
.black-drawer
  color #fff
  .form-list
    overflow hidden
    margin-bottom 24px
    font-size 14px
    .button-s-add
      margin-right 12px
    li
      display inline-block
      margin-bottom 24px
      span
        color #B2B3BD
    li:nth-child(2n+1)
      margin-right 24px
  .handle-panel
    position absolute
    top 13px
    right 24px
    li
      display inline-block
      margin-left 32px
      cursor pointer
      font-size 16px
      &:hover
        color #f89407
        i:before
          color #f89407
  h3.form-item-title
    background #1E1E23
    height 53px
    line-height 53px
    border-bottom 1px solid #373A43
    padding-bottom 24px
    padding-left 24px
    box-sizing border-box
  .shadow-box
    background #1E1E23
    border-radius 2px
    padding 24px 24px 0 24px
    overflow hidden
  .border-box
    border 1px solid #373A43
    border-radius 2px
    padding 16px
    margin-bottom 24px
    li
      span
        vertical-align middle
    li:first-child
      margin-bottom 18px
  .shadow-title
    background #1E1E23
    padding 16px 24px
  .cascader-solid .el-input__inner
    text-indent 0
</style>
