<template>
  <div
    class="leasing-plan"
    v-loading="loading"
  >
    <template v-if="hasPermission">
      <div class="common-shadow-panel form-search-panel">
        <ul>
          <li>
            <span class="commonFields">楼宇楼层 :</span>
            <el-cascader
              class="cascader-solid"
              popper-class="yellow-select-panel"
              :options="buildingFloorOptions"
              v-model="formSearch.buildingFloor"
              collapse-tags
              placeholder="全部"
              clearable
            ></el-cascader>
          </li>
          <li>
            <span class="commonFields">铺位号 :</span>
            <div class="native-input-solid">
              <input
                type="text"
                v-model="formSearch.unitNumber"
                placeholder="请输入"
              />
            </div>
          </li>
          <li>
            <span class="commonFields">铺位类型 :</span>
            <div class="native-input-solid">
              <el-select
                class="input-solid"
                popper-class="w-block-select-down yellow-select-panel"
                filterable
                size="small"
                placeholder="请选择"
                v-model="formSearch.unitType"
              >
                <el-option
                  v-for="item in unitTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </li>
          <li>
            <span class="commonFields">主次力店 :</span>
            <div class="native-input-solid">
              <el-select
                class="input-solid"
                popper-class="w-block-select-down yellow-select-panel"
                filterable
                size="small"
                placeholder="请选择"
                v-model="formSearch.storeType"
              >
                <el-option
                  v-for="item in storeTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </li>
          <li>
            <span class="commonFields">规划业态 :</span>
            <div class="native-input-solid">
              <el-select
                class="input-solid"
                popper-class="w-block-select-down yellow-select-panel"
                filterable
                size="small"
                placeholder="请选择"
                v-model="formSearch.planFormat"
              >
                <el-option
                  v-for="item in planFormatOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </li>
          <li v-if="false">
            <span class="commonFields">审批状态 :</span>
            <div class="native-input-solid">
              <el-select
                class="input-solid"
                popper-class="w-block-select-down yellow-select-panel"
                filterable
                size="small"
                placeholder="请选择"
                v-model="formSearch.auditState"
              >
                <el-option
                  v-for="item in auditStateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </li>
          <li>
            <span class="commonFields">招商品牌级次 :</span>
            <div class="native-input-solid">
              <el-select
                class="input-solid"
                popper-class="w-block-select-down yellow-select-panel"
                filterable
                size="small"
                placeholder="请选择"
                v-model="formSearch.brandLevel"
              >
                <el-option
                  v-for="item in brandLevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </li>
        </ul>
        <div class="fixed-btn">
          <span class="commonFields"></span>
          <span
            class="button-basic button-primary"
            @click="toSearch"
          >查询</span>
        </div>
      </div>
      <div
        class="common-shadow-panel position-relative"
        style="min-height:733px;"
      >
        <h2 class="w-title-gradients">租金计划</h2>
        <ul class="handle-panel">
          <li @click="checkPowerAuth('leadingin')">
            <i class="icon-import-file"></i>
            导入
          </li>
          <li @click="checkPowerAuth('leadingout')">
            <i class="icon-export-file"></i>
            导出
          </li>
          <li @click="checkPowerAuth('setmainshop')">
            <i class="icon-set"></i>
            主次力店设置
          </li>
        </ul>
        <el-table
          :data="tbsData"
          class="tbs-black"
          show-overflow-tooltip
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <ul
                v-for="(item,index) in props.row.rentSetting"
                :key="index"
              >
                <li>
                  阶段时间：
                  <span>{{item.phaseTime || '-'}}</span>
                </li>
                <li>
                  策略租金：
                  <span>{{(item.strategyPrice || '-') + props.row.unit}}</span>
                </li>
                <li>
                  租金市场价：
                  <span>{{(item.marketPrice || '-') + props.row.unit}}</span>
                </li>
                <li>
                  租金标准价：
                  <span>{{(item.rentPrice || '-') + props.row.unit}}</span>
                </li>
                <li>
                  租金保底价：
                  <span>{{(item.bottomPrice || '-') + props.row.unit}}</span>
                </li>
                <li>
                  租金递增率：
                  <span>{{item.rentIncream || '-'}}</span>
                </li>
                <li>
                  免租时长：
                  <span>{{item.freeLength || '-'}}天</span>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            label="楼宇"
            prop="building"
            width="80"
          >
          </el-table-column>
          <el-table-column
            label="楼层"
            prop="floor"
            width="80"
          >
          </el-table-column>
          <el-table-column
            label="铺位号"
            prop="berthNo"
            width="150"
          >
            <template slot-scope="scope">
              <div
                class="nowrap-box"
                :title="scope.row.berthNo"
              >
                {{scope.row.berthNo}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="铺位类型"
            prop="unitType"
            width="90"
          >
          </el-table-column>
          <el-table-column
            label="招商品牌级次"
            prop="level"
            width="122"
          >
          </el-table-column>
          <el-table-column
            label="规划业态"
            prop="industry1"
            width="150"
          >
          </el-table-column>
          <el-table-column
            label="主次力店"
            prop="storeType"
            width="122"
          >
          </el-table-column>
          <el-table-column
            label="生效时间"
            prop="effectTime"
            width="230"
          >
          </el-table-column>
          <el-table-column
            :label="`${user.topuid === 6467 ? '物业' : '综合'}管理费`"
            prop="managementCost"
            width="150"
          >
          </el-table-column>
          <el-table-column
            label="计租面积"
            prop="rentArea"
            width="122"
          >
          </el-table-column>
          <el-table-column
            label="租赁年限"
            prop="leaseLength"
            width="122"
          >
          </el-table-column>
          <el-table-column
            v-if="false"
            label="审批状态"
            prop="approvalStatus"
            width="122"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <span
                class="default-link"
                @click="checkPowerAuth('edit', scope.$index, scope.row)"
              >编辑</span>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="w-page-sty"
          v-if="formSearch.total > 10"
        >
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handlePage"
            :current-page="formSearch.pageNum"
            :page-size="formSearch.pageSize"
            :total="formSearch.total"
          >
          </el-pagination>
          <br>
        </div>
      </div>
      <rent-form
        ref="rentForm"
        :planFormatOptions="planFormatOptions"
        :projectId="projectId"
        :decisionId="decisionId"
        @refreshData="refreshData"
      ></rent-form>
      <el-drawer
        title="主次力店规则设置"
        :visible.sync="openMainShopSet"
        class="main-shop-set"
        size="662px"
      >
        <h3 class="w-title-border-left">规则设置</h3>
        <el-form
          ref="form"
          :model="mainShopForm"
          label-position="right"
        >
          <el-form-item
            label="划分面积类型"
            label-width="100px"
          >
            <div class="native-input-solid">
              <el-select
                v-model="mainShopForm.areaType"
                placeholder="请选择"
                class="input-solid"
                popper-class="w-block-select-down yellow-select-panel"
              >
                <el-option
                  v-for="item in areaType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            label="主力店"
            label-width="100px"
          >
            <div class="add-shop-data">
              <div class="native-input-solid">
                <el-select
                  v-model="mainShopForm.symbolMaf"
                  placeholder="请选择"
                  class="input-solid"
                  popper-class="w-block-select-down yellow-select-panel"
                >
                  <el-option
                    v-for="item in symbol"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model.number="mainShopForm.mainAreaFirst"
                  type="number"
                  class="main-shop-set-input"
                  placeholder="请输入"
                  @input="checkMainShopSet"
                ></el-input>
                <span class="unit">㎡</span>
              </div>
              <span style="margin: 0 10px; color: #808191">且</span>
              <div class="native-input-solid">
                <el-select
                  v-model="mainShopForm.symbolMas"
                  placeholder="请选择"
                  class="input-solid"
                  popper-class="w-block-select-down yellow-select-panel"
                >
                  <el-option
                    v-for="item in symbol"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model.number="mainShopForm.mainAreaSecond"
                  type="number"
                  class="main-shop-set-input"
                  placeholder="请输入"
                  @input="checkMainShopSet"
                ></el-input>
                <span class="unit">㎡</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="次主力店"
            label-width="100px"
          >
            <div class="add-shop-data">
              <div class="native-input-solid">
                <el-select
                  v-model="mainShopForm.symbolSaf"
                  placeholder="请选择"
                  class="input-solid"
                  popper-class="w-block-select-down yellow-select-panel"
                >
                  <el-option
                    v-for="item in symbol"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model.number="mainShopForm.secondaryAreaFirst"
                  type="number"
                  class="main-shop-set-input"
                  placeholder="请输入"
                  @input="checkMainShopSet"
                ></el-input>
                <span class="unit">㎡</span>
              </div>
              <span style="margin: 0 10px; color: #808191">且</span>
              <div class="native-input-solid">
                <el-select
                  v-model="mainShopForm.symbolSas"
                  placeholder="请选择"
                  class="input-solid"
                  popper-class="w-block-select-down yellow-select-panel"
                >
                  <el-option
                    v-for="item in symbol"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model.number="mainShopForm.secondaryAreaSecond"
                  type="number"
                  class="main-shop-set-input"
                  placeholder="请输入"
                  @input="checkMainShopSet"
                ></el-input>
                <span class="unit">㎡</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="main-shop-set-footer">
          <span
            :style="!minShopFormCheck ? 'background: #905E1B; color: #939498' : ''"
            class="button-basic button-primary"
            @click="mainShopSetData"
          >保存</span>
          <span
            class="button-basic button-low"
            @click="openMainShopSet = false"
          >取消</span>
        </div>
      </el-drawer>
      <import-plan
        v-if="showImportPlan"
        :decisionId="decisionId"
        :projectId="projectId"
        @addDropBrandEmitSuccess="addDropBrandEmitSuccess"
        @closePanel="closePanel"
      >
      </import-plan>
    </template>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import rentForm from '../components/handleRentForm.vue'
import importPlan from '../components/importRentPlan.vue'
import { powerAuth } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'leasingPlan',
  data () {
    return {
      loading: false,
      hasPermission: true,
      showImportPlan: false,
      formSearch: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        buildingFloor: '',
        unitNumber: '',
        unitType: '',
        storeType: '',
        planFormat: '全部',
        auditState: '',
        brandLevel: ''
      },
      unitTypeOptions: [
        { label: '全部', value: '' },
        { label: '铺位', value: 1 },
        { label: '广告位', value: 2 },
        { label: '场地', value: 3 }
        // { label: '写字楼', value: 4 }
      ],
      storeTypeOptions: [
        { label: '全部', value: '' },
        { label: '主力店', value: 1 },
        { label: '次主力店', value: 2 },
        { label: '普通店', value: 0 }
      ],
      planFormatOptions: [],
      auditStateOptions: [
        { label: '全部', value: '' },
        { label: '-', value: '-' },
        { label: '审批完成', value: '审批完成' },
        { label: '审批中', value: '审批中' }
      ],
      brandLevelOptions: [
        { label: '全部', value: '' },
        { label: '不限', value: 1 },
        { label: 'S', value: 2 },
        { label: 'A', value: 3 },
        { label: 'B', value: 4 },
        { label: 'C', value: 5 },
        { label: 'D', value: 6 }
        // { label: '标杆', value: 2 },
        // { label: '标准', value: 3 },
        // { label: '非标', value: 4 }
      ],
      tbsData: [{}],
      buildingFloorOptions: [],
      openMainShopSet: false,
      areaType: [
        { value: 0, label: '建筑面积' },
        { value: 1, label: '使用面积' }
      ],
      symbol: [
        { value: 1, label: '>' },
        { value: 2, label: '<' },
        { value: 3, label: '>=' },
        { value: 4, label: '<=' }
      ],
      dialogType: '',
      mainShopForm: {
        areaType: 0,
        mainAreaFirst: null,
        mainAreaSecond: null,
        symbolMaf: 1,
        symbolMas: 1,
        secondaryAreaFirst: null,
        secondaryAreaSecond: null,
        symbolSaf: 1,
        symbolSas: 1
      },
      minShopFormCheck: false
    }
  },
  components: { rentForm, importPlan },
  props: {
    projectId: {
      type: Number
    },
    decisionId: {
      type: Number
    },
    projectName: {
      type: String
    },
    exportLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    projectId (newValue, oldValue) {
      console.log('projectId', this.projectId)
      this.leasingPlanList()
      this.getFilterFloorFunc() // 楼宇楼层
    },
    decisionId (newValue, oldValue) {
      this.leasingPlanList()
      this.getFilterFloorFunc()
    }
  },
  mounted () {
    if (powerAuth(212, 'show')) {
      this.leasingPlanList()
      this.getFilterFloorFunc()
      this.getDataFormatList()
    } else {
      this.hasPermission = false
    }
  },
  methods: {
    checkPowerAuth (keyword, data1, data2) {
      const flag = powerAuth(212, keyword)
      if (!flag) {
        this.$message({
          type: 'warning',
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return
      }
      switch (keyword) {
        case 'edit':
          this.handleEdit(data1, data2)
          break
        case 'leadingin':
          this.showImportPlanPanel()
          break
        case 'leadingout':
          this.exportFile()
          break
        case 'setmainshop':
          this.openMainShopSet = true
          this.getMainShopData()
          break
        default:
          break
      }
    },
    getDataFormatList () {
      this.axios.post(api.getListIndustry1, {}).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.planFormatOptions = resData.map(item => {
            const tempObj = {
              label: item.name,
              value: item.id
            }
            return tempObj
          })
          this.planFormatOptions.unshift({
            label: '全部',
            value: '全部'
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    closePanel () {
      document.body.style.overflowY = 'auto'
      this.showImportPlan = false
    },
    addDropBrandEmitSuccess () {
      this.leasingPlanList(1)
      this.getFilterFloorFunc()
      this.getDataFormatList()
    },
    showImportPlanPanel () {
      document.body.style.overflowY = 'hidden'
      this.showImportPlan = true
    },
    exportPlan () {
      const params = {
        pageNum: this.formSearch.pageNum,
        pageSize: this.formSearch.pageSize,
        decisionId: this.decisionId,
        projectId: this.projectId
      }
      if (this.formSearch.buildingFloor && this.formSearch.buildingFloor.length) {
        params.building = this.formSearch.buildingFloor[0]
        params.floor = this.formSearch.buildingFloor[1]
      }
      this.formSearch.auditState && (params.approvalStatus = this.formSearch.auditState)
      this.formSearch.unitNumber && (params.berthNo = this.formSearch.unitNumber)
      this.formSearch.unitType && (params.berthType = this.formSearch.unitType)
      this.formSearch.brandLevel && (params.brandLevel = this.formSearch.brandLevel)
      this.formSearch.planFormat && this.formSearch.planFormat !== '全部' && (params.industry1 = this.formSearch.planFormat)
      params.mainBerthType = this.formSearch.storeType
      return this.axios({ // 用axios发送post请求
        method: 'get',
        url: api.exportRentPlan2, // 请求地址
        params: params, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    exportFile () {
      this.$emit('update:exportLoading', true)
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      this.exportPlan().then(res => { // 处理返回的文件流
        console.log('res', res)
        const blob = new Blob([res.data])
        const fileName = '【' + this.projectName + '】' + '租金计划清单-' + year + '-' + month + '-' + day + '.xlsx'
        // 兼容ie
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)
        }
        this.$emit('update:exportLoading', false)
      })
    },
    refreshData () {
      console.log('myself')
      this.$emit('refreshData')
    },
    getFilterFloorFunc (id) { // 楼宇楼层options
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          this.buildingFloorOptions = res.data.data.map(item => {
            for (let i = 0; i < item.children.length; i++) {
              const item1 = item.children[i]
              item1.children = null
            }
            return item
          })
        })
    },
    handlePage (num) { // 切换列表页面
      this.formSearch.pageNum = num
      this.leasingPlanList()
    },
    leasingPlanList (key) { // 租金计划列表
      if (!key) {
        this.loading = true
      }
      const params = {
        pageNum: this.formSearch.pageNum,
        pageSize: this.formSearch.pageSize,
        decisionId: this.decisionId,
        projectId: this.projectId
      }
      if (this.formSearch.buildingFloor && this.formSearch.buildingFloor.length) {
        params.building = this.formSearch.buildingFloor[0]
        params.floor = this.formSearch.buildingFloor[1]
      }
      this.formSearch.auditState && (params.approvalStatus = this.formSearch.auditState)
      this.formSearch.unitNumber && (params.berthNo = this.formSearch.unitNumber)
      this.formSearch.unitType && (params.berthType = this.formSearch.unitType)
      this.formSearch.brandLevel && (params.brandLevel = this.formSearch.brandLevel)
      this.formSearch.planFormat && this.formSearch.planFormat !== '全部' && (params.industry1 = this.formSearch.planFormat)
      params.mainBerthType = this.formSearch.storeType
      this.axios.get(api.leasingPlanList, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          this.formSearch.pageNum = resData.pageNum
          this.formSearch.pageSize = resData.pageSize
          this.formSearch.total = resData.total
          this.tbsData = resData.dataList.map(item => {
            item.unitType = item.berthType ? ['铺位', '广告位', '场地', '写字楼'][item.berthType - 1] : '-'
            // 品牌级次模块
            // item.level = item.brandLevel ? ['不限', '标杆', '标准', '非标'][item.brandLevel - 1] : '-'
            item.level = item.brandLevel ? ['不限', 'S', 'A', 'B', 'C', 'D'][item.brandLevel - 1] : '-'
            item.storeType = item.mainBerthType !== null ? ['普通店', '主力店', '次主力店'][item.mainBerthType] : '-'
            item.industry1 = item.industry1 || '-'
            if (item.effectStartDate && item.effectEndDate) {
              item.effectTime = item.effectStartDate + '-' + item.effectEndDate
            } else {
              item.effectTime = '-'
            }
            if (item.rentMinYear && item.rentMaxYear) {
              item.leaseLength = item.rentMinYear + '-' + item.rentMaxYear
              item.leaseLength += '年'
            } else {
              item.leaseLength = '-'
            }
            if (!item.managementCost) {
              item.managementCost = '-'
            } else {
              item.managementCost += item.unit
            }
            if (!item.rentArea) {
              item.rentArea = '-'
            } else {
              item.rentArea += '㎡'
            }
            item.rentSetting = item.childs.map(item1 => {
              if (item1.stepStartDate && item1.stepEndDate) {
                item1.phaseTime = item1.stepStartDate + '-' + item1.stepEndDate
              } else {
                item1.phaseTime = '-'
              }
              return item1
            })
            return item
          })
          if (!key) {
            this.loading = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleEdit (index, data) {
      this.$refs.rentForm.handleForm({
        berthId: data.berthId,
        decisionId: this.decisionId,
        projectId: this.projectId
      })
    },
    toSearch () {
      this.formSearch.pageNum = 1
      this.leasingPlanList()
    },
    // 获取主次力店规则数据
    getMainShopData () {
      this.loading = true
      this.axios.post(api.mainSecondaryStoreConfigureList, {
        decisionId: this.decisionId,
        projectId: this.projectId
      }).then((res) => {
        this.loading = false
        if (res.data.data.length === 0) {
          this.dialogType = 'add'
          this.minShopFormCheck = false
        } else {
          this.mainShopForm = res.data.data[0]
          this.dialogType = 'edit'
          this.minShopFormCheck = true
        }
      })
    },
    // 检查是否填写数据
    checkMainShopSet () {
      const form = this.mainShopForm
      if (form.mainAreaFirst !== null && form.mainAreaSecond !== null && form.secondaryAreaFirst !== null && form.secondaryAreaSecond !== null && form.mainAreaFirst !== '' && form.mainAreaSecond !== '' && form.secondaryAreaFirst !== '' && form.secondaryAreaSecond !== '') {
        this.minShopFormCheck = true
      } else {
        this.minShopFormCheck = false
      }
    },
    // 主次力店设置提交数据
    mainShopSetData () {
      const form = this.mainShopForm
      if (!this.minShopFormCheck) {
        this.$message.error('请设置主/次力店的区间值')
        return
      }
      let apiStr = ''
      if (this.dialogType === 'add') {
        apiStr = api.mainSecondaryStoreConfigure
      } else {
        apiStr = api.mainSecondaryStoreConfigureUpdate
      }
      this.loading = true
      this.axios.post(apiStr, {
        areaType: form.areaType,
        decisionId: this.decisionId,
        mainAreaFirst: form.mainAreaFirst,
        mainAreaSecond: form.mainAreaSecond,
        project: [{ value: this.projectId, label: this.projectName }],
        secondaryAreaFirst: form.secondaryAreaFirst,
        secondaryAreaSecond: form.secondaryAreaSecond,
        symbolMaf: form.symbolMaf,
        symbolMas: form.symbolMas,
        symbolSaf: form.symbolSaf,
        symbolSas: form.symbolSas
      }).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.dialogType = 'edit'
          this.openMainShopSet = false
          this.minShopFormCheck = true
          this.leasingPlanList()
          this.getFilterFloorFunc()
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.leasing-plan>>>
  overflow hidden
  .main-shop-set
    .native-input-solid
      .el-input__inner::-webkit-input-placeholder
        color #808191 !important
      .el-input__inner:focus
        background #1E1E23 !important
    .el-drawer__header
      color #FFF
      background #32343B
      margin -24px -24px 8px
      padding-bottom 24px
      font-weight bold
    .el-drawer
      padding 24px
      background #272930
    .el-form
      padding 24px 0
      background #1E1E23
      .el-form-item
        padding 0 24px
      .el-form-item:first-child
        padding-bottom 24px
        border-bottom 1px solid #373A43
        .el-form-item__label
          color #B2B3BD
      .el-form-item:last-child
        margin-bottom 0
      input::placeholder
        color #808191
      .el-input__inner, .el-input__icon, .el-form-item__content
        height 36px
        line-height 36px
      .el-select
        border-radius 2px
      .el-select, .el-input, .unit
        background #373A43
        input
          border none
      .unit
        position absolute
        height 34px
        line-height 31px
        top 1px
        right 1px
        padding-right 16px
        background none
      .el-form-item__label
        color #808191
      .add-shop-data
        display flex
        .native-input-solid
          display flex
          position relative
          border-radius 2px
          .el-input
            width unset
          .el-select
            width 70px
            .el-input__inner
              width 100%
          .main-shop-set-input
            .el-input__inner
              width 143px
              input
                padding-right 35px
    .main-shop-set-footer
      display flex
      justify-content center
      padding 20px 0
      text-align center
      position absolute
      left 0
      right 0
      bottom 0
      border-top 1px solid #373A43
      span:last-child
        margin-left 24px
  .el-range-input
    width 39% !important
    border none
    outline none
  .el-range-separator
    color #fff
  .el-tooltip
    margin-left 12px
  .form-search-panel
    padding 40px 40px 16px 0px
    min-height auto !important
    font-size 14px
    position relative
    .fixed-btn
      position absolute
      top 45px
      right 36px
    ul
      width 92%
      position relative
      left -20px
      li
        overflow hidden
        display inline-block
        margin-bottom 24px
        .commonFields
          width 135px
        .native-input-solid, .cascader-solid
          width 206px
  .handle-panel
    position absolute
    top 13px
    right 24px
    li
      display inline-block
      margin-left 32px
      cursor pointer
      font-size 16px
      &:hover
        color #f89407
        i:before
          color #f89407
  .tbs-black
    width auto
    margin 12px 16px
    min-height 437px
  .cascader-solid .el-input__inner
    text-indent 0
</style>
